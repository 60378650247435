
// 
// breadcrumb.scss
//

.breadcrumb {
    // scss-docs-start breadcrumb-css-vars
    // without this the styles are taken from /node_modules/bootstrap instead of lightmode/darkmode
    --#{$prefix}breadcrumb-divider-color: unset;
    --#{$prefix}breadcrumb-active-color: unset;
    // scss-docs-end breadcrumb-css-vars
}  


// Breadcrumb item arrow
.breadcrumb-item {
    > a {
        color: #{map-get($grays, "700")};
    }
    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
        }
    }
}


// dark mode
body[data-layout-color="dark"] {
    .breadcrumb-item {
        > a {
            color: #{map-get($dark-grays, "700")};
        }
    }
}
  