// 
// card.scss
//

.card {

    // scss-docs-start card-css-vars - unsetting here to allow _light_mode.scss and dark_mode.scss styles to be applied
    // without this the styles are taken from /node_modules/bootstrap instead of lightmode/darkmode
    --#{$prefix}card-border-color: unset;
    --#{$prefix}card-box-shadow: unset;
    --#{$prefix}card-cap-bg: unset;
    --#{$prefix}card-bg: unset;
    // scss-docs-end card-css-vars

    box-shadow: $box-shadow-sm;
    margin-bottom: $grid-gutter-width;

    .card-drop {
        color: #{map-get($grays, "600")};
        font-size: 20px;
        display: inline-block;
        line-height: 1px;
    }
}

.card-body.mouse-pointer>a * {
    cursor: pointer;
}

.card-body.mouse-pointer * {
    cursor: pointer;
}

.card-body.fixed-padding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.card-body.sisense-indicator {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.card-body.sisense-indicator h4 {
    padding: 0.5rem;
}

.card-body.sisense-indicator sisense-widget {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.card-widgets {
    float: right;
    height: 16px;

    >a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F0415";
                }
            }
        }
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}


//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: $card-overlay-bg;
    cursor: progress;

}


.header-title {
    font-size: 1rem;
    margin: 0 0 0px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $text-muted;
}





// dark mode
body[data-layout-color="dark"] {
    .card {
        .card-drop {
            color: #{map-get($dark-grays, "600")};
        }
    }
}