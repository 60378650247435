// 
// toast.scss
//
.toast {
    // scss-docs-start toast-css-vars
    // without this the styles are taken from /node_modules/bootstrap instead of lightmode/darkmode
    --#{$prefix}toast-background-color: unset;
    --#{$prefix}toast-border-color: unset;
    --#{$prefix}toast-header-color: unset;
    --#{$prefix}toast-header-background-color: unset;
    --#{$prefix}toast-header-border-color: unset;
    // scss-docs-end toast-css-vars
}  