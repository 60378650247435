// 
// accordions.scss
//
.accordion {
    // scss-docs-start accordion-variables
    // without this the styles are taken from /node_modules/bootstrap instead of lightmode/darkmode
    --#{$prefix}accordion-color: unset;
    --#{$prefix}accordion-border-color: #{rgba($black, 0.125)};
    --#{$prefix}accordion-button-active-bg: #{tint-color($primary, 90%)};
    --#{$prefix}accordion-button-active-color: #{shade-color($primary, 10%)};
    --#{$prefix}accordion-button-focus-border-color: #{$input-focus-border-color};
    --#{$prefix}accordion-button-focus-box-shadow: #{$btn-focus-box-shadow};

    // scss-docs-end accordion-variables
}

.custom-accordion {
    .accordion-arrow {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
    }

    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: "\F0142";
                }
            }
        }
    }
}

.custom-accordion {
    .card {
        box-shadow: none;
        border: 1px solid $border-color !important;
    }
}