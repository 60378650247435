// 
// modal.scss
//

.modal {
    // scss-docs-start modal-css-vars
    // without this the styles are taken from /node_modules/bootstrap instead of lightmode/darkmode
    --#{$prefix}modal-content-bg: unset;
    --#{$prefix}modal-content-box-shadow-xs: 0 0.25rem 0.5rem unset;
    --#{$prefix}modal-content-box-shadow-sm-up: 0 0.5rem 1rem unset;
    --#{$prefix}modal-backdrop-bg: unset;
    --#{$prefix}modal-header-border-color: unset;
    --#{$prefix}modal-footer-border-color: unset;
    // scss-docs-end modal-css-vars
}

// Title text within header
.modal-title {
    margin-top: 0;
}

// Modal full width
.modal-full-width {
    width: 95%;
    max-width: none;
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}

// Right Modal
.modal-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;
    transform: translate(25%, 0) !important;

    button.btn-close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
}

.modal {
    &.show {

        .modal-right,
        .modal-left {
            transform: translate(0, 0) !important;
        }
    }
}

// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Colored modal header
.modal-colored-header {
    color: $white;
    border-radius: 0;

    .btn-close {
        color: $white !important;
    }
}