// 
// pagination.scss
//

.pagination {
    // scss-docs-start pagination-css-vars
    // without this the styles are taken from /node_modules/bootstrap instead of lightmode/darkmode
    --#{$prefix}pagination-color: unset;
    --#{$prefix}pagination-bg: unset;
    --#{$prefix}pagination-border-color: unset;
    --#{$prefix}pagination-focus-color: unset;
    --#{$prefix}pagination-focus-bg: unset;
    --#{$prefix}pagination-focus-box-shadow: unset;
    --#{$prefix}pagination-hover-color: unset;
    --#{$prefix}pagination-hover-bg: unset;
    --#{$prefix}pagination-hover-border-color: unset;
    --#{$prefix}pagination-disabled-color: unset;
    --#{$prefix}pagination-disabled-bg: unset;
    --#{$prefix}pagination-disabled-border-color: unset;
    // scss-docs-end pagination-css-vars
}


// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 6px;
        border: none;
    }
    .page-item {
        &:last-child {
            .page-link {
                margin-right: 0px;
            }
        }
    }
}

.pagination {
    a {
        line-height: $line-height-sm !important;
    }
}