// 
// popover.scss
//

.popover {
    // scss-docs-start popover-css-vars
    // without this the styles are taken from /node_modules/bootstrap instead of lightmode/darkmode
    --#{$prefix}popover-bg: unset;
    --#{$prefix}popover-border-color: unset;
    --#{$prefix}popover-header-bg: unset;
    --#{$prefix}popover-header-color: unset;
    --#{$prefix}popover-body-color: unset;
    --#{$prefix}popover-arrow-color: unset;
    --#{$prefix}popover-arrow-outer-color: unset;
    // scss-docs-end popover-css-vars
}

.popover-header {
    margin-top: 0;
}