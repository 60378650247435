// 
// list-group.scss
//
.list-group {
    // scss-docs-start list-group-css-vars
    // without this the styles are taken from /node_modules/bootstrap instead of lightmode/darkmode
    --#{$prefix}list-group-color: unset;
    --#{$prefix}list-group-bg: unset;
    --#{$prefix}list-group-border-color: unset;
    --#{$prefix}list-group-hover-bg: unset;
    --#{$prefix}list-group-disabled-color: unset;
    --#{$prefix}list-group-disabled-bg: unset;
    --#{$prefix}list-group-action-color: unset;
    --#{$prefix}list-group-action-active-color: unset;
    --#{$prefix}list-group-action-active-bg: unset;
    // scss-docs-end list-group-css-vars
}  